import React, { useEffect } from "react"
import SwiperCore, { Pagination, Autoplay, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import $ from "jquery"
import "swiper/swiper.scss"
import "swiper/components/pagination/pagination.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"

SwiperCore.use([Pagination, Autoplay, Mousewheel])

export default function AnaSayfa({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()

  useEffect(() => {
    $(".swiper-pagination-bullets .swiper-pagination-bullet:nth-child(1)").html(
      `<span>${t("eser")}</span>`
    )
    $(".swiper-pagination-bullets .swiper-pagination-bullet:nth-child(3)").html(
      `<span>${t("takvim")}</span>`
    )
    $(".swiper-pagination-bullets .swiper-pagination-bullet:nth-child(5)").html(
      `<span>${t("sergi")}</span>`
    )
    $(".swiper-pagination-bullets .swiper-pagination-bullet:nth-child(7)").html(
      `<span>${t("koleksiyon")}</span>`
    )
    $(".swiper-pagination-bullets").hover(function () {
      $(this).toggleClass("hover")
    })
    $("header").addClass("index")
    $("footer").css("display", "none")
  }, [t])

  let base
  if (typeof document !== "undefined") {
    base = window.innerWidth > 1279 ? data?.data : data?.data_mobil
  }

  const slides = base?.edges?.[0].node?.frontmatter?.slide?.sort((a, b) => {
    if (a.order > b.order) return 1
    if (a.order < b.order) return -1
    return 0
  })

  return (
    <Layout>
      <SEO title="Ana Sayfa" />
      <Swiper
        loop="true"
        direction="vertical"
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        touchRatio="0"
        mousewheel={{
          invert: false,
        }}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        breakpoints={{
          320: {
            touchRatio: "1",
          },
          1280: {
            touchRatio: "0",
          },
        }}
      >
        {slides?.map((slide, index) => {
          return (
            <SwiperSlide
              style={{
                background: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              key={slide.image + "-" + index}
            >
              <span className="slider-title">
                {language === "en" ? slide.title_en : slide.title}
                <span className="slider-subtitle">
                  {language === "en" ? slide.subtitle_en : slide.subtitle}
                </span>
              </span>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Slides {
    data: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "slide" } } }
    ) {
      edges {
        node {
          frontmatter {
            slide {
              image
              name
              subtitle
              subtitle_en
              title
              title_en
              order
            }
          }
        }
      }
    }
    data_mobil: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "mobil_slide" } } }
    ) {
      edges {
        node {
          frontmatter {
            slide {
              image
              name
              subtitle
              subtitle_en
              title
              title_en
              order
            }
          }
        }
      }
    }
  }
`
